import './SideMenu.css';
import React, {useEffect, useState} from 'react';
import {FaCalculator, FaDollarSign, FaSignOutAlt, FaArrowCircleLeft} from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';
import Logo from '../../images/Bitlendr_transparent.svg';
import LogoSmall from '../../images/Bitlendr_transparent_small.png';
import { signOut } from 'aws-amplify/auth';
import {Box} from "@mui/material";


export enum SideMenuItems {
    CALCULATOR = 'calculator',
    LOANS = 'loans',
    SETTINGS = 'settings'
}

export interface SideMenuProps {
    initialItem: SideMenuItems;
}

export const SideMenu = ({initialItem}: SideMenuProps) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<SideMenuItems>(initialItem);
    const navigate = useNavigate();

    const navigateCalculator = () => {
        navigate('/home', {
            state: {
            }
        });
    };

    const navigateLoans = () => {
        navigate('/list', {
            state: {
            }
        });
    };

    const navigateSettings = () => {
        navigate('/settings', {
            state: {
            }
        });
    };

    useEffect(() => {
        setSelectedItem(initialItem);
    }, [initialItem]);

    const toggleCollapse = () => {
        if (isExpanded) {
            setIsExpanded(false);
        }
    };

    const toggleExpand = () => {
        if (!isExpanded) {
            setIsExpanded(true);
        }
    };

    const handleMenuItemClick = (item: SideMenuItems) => {
        setSelectedItem(item);
        switch (item) {
            case SideMenuItems.CALCULATOR:
                navigateCalculator();
                break;
            case SideMenuItems.LOANS:
                navigateLoans();
                break;
            case SideMenuItems.SETTINGS:
                navigateSettings();
                break;
        }
    };

    const handleLogOut = async () => {
        console.log("Should sign out here!!!");
        await signOut({global: true});
        window.location.reload();
    }

    return (
        <div className={`side-menu ${isExpanded ? 'expanded' : 'collapsed'}`}>
            <Box
                display="flex"
                flexDirection="column"
                onClick={toggleExpand}
                sx={{
                    marginTop: '12px'
                }}
            >
                {
                    isExpanded ? (
                        <Box
                            display="flex"
                            alignContent="space-between"
                            justifyContent="center"
                            sx={{marginTop: '4px'}}
                        >
                            <img src={Logo} alt={'Bitlendr'} style={{maxHeight: '16px', marginTop: '2px'}}/>
                            <button className="toggle-button" onClick={toggleCollapse}>
                                <FaArrowCircleLeft size={'16px'}/>
                            </button>
                        </Box>
                    ) : (
                            <img src={LogoSmall} alt={'Bitlendr'} style={{maxHeight: '32px'}}/>
                    )
                }
            </Box>
            <div className="menu-content">
                <ul>
                    <li
                        className={`calculator-item ${selectedItem === SideMenuItems.CALCULATOR ? 'selected' : ''}`}
                        onClick={() => handleMenuItemClick(SideMenuItems.CALCULATOR)}
                    >
                        <FaCalculator className="icon" />
                        {
                            (isExpanded || selectedItem === SideMenuItems.CALCULATOR) &&
                            <span className="text">Loan Calculator</span>
                        }
                    </li>
                    <li
                        className={`loans-item ${selectedItem === SideMenuItems.LOANS ? 'selected' : ''}`}
                        onClick={() => handleMenuItemClick(SideMenuItems.LOANS)}
                    >
                        <FaDollarSign className="icon" />
                        {
                            (isExpanded || selectedItem === SideMenuItems.LOANS) &&
                            <span className="text">My Loans</span>
                        }
                    </li>
                    {/*<li*/}
                    {/*    className={`settings-item ${selectedItem === SideMenuItems.SETTINGS ? 'selected' : ''}`}*/}
                    {/*    onClick={() => handleMenuItemClick(SideMenuItems.SETTINGS)}*/}
                    {/*>*/}
                    {/*    <FaCog className="icon" />*/}
                    {/*    {*/}
                    {/*        (isExpanded || selectedItem === SideMenuItems.SETTINGS) &&*/}
                    {/*        <span className="text">Settings</span>*/}
                    {/*    }*/}
                    {/*</li>*/}
                </ul>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', marginBottom: '2vh'}}>
                <div onClick={handleLogOut} style={{display: 'flex', flexDirection: 'row', alignItems: "center", cursor: "pointer"}}>
                    <FaSignOutAlt className="icon" />
                    {
                        isExpanded &&
                        <span className="text">Log out</span>
                    }
                </div>
                {
                    isExpanded &&
                    <div className="version">Ver 1.0 Beta</div>
                }
            </div>
        </div>
    );
};

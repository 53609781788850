import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {MobileMenuBar} from "../menu/MobileMenuBar";
import {Box, Button, Grid, Typography} from "@mui/material";
import {LoanStatus} from "../loanListItem/LoanStatus";
import {Status} from "../types";

interface ReceiptProps {
    isMobile: boolean;
}

export const Receipt = ({ isMobile }: ReceiptProps) => {
    const location = useLocation();
    const {
        cryptoAmount,
        quantity,
        selectedCrypto,
        selectedCurrency,
        depositAddress,
        depositPublicKey
    } = location.state || {};

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/list', {
            state: {
            }
        });
    };

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={isMobile ? 11 : 8}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="20px"
                    sx={{
                        background: '#FAFAFA',
                        padding: '20px',
                        borderRadius: '20px',
                    }}
                >
                    <LoanStatus isMobile={isMobile} status={Status.PENDING} />

                    <Box mb={isMobile ? 0 : 1} >
                        <Typography
                            alignContent="center"
                            justifyContent="center"
                            color="#1B2030"
                            fontSize={isMobile ? 'x-large' : 'xx-large'}
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            {cryptoAmount} {selectedCrypto}
                        </Typography>
                    </Box>

                    <Box mb={isMobile ? 1 : 2} >
                        <Typography
                            alignContent="center"
                            justifyContent="center"
                            color="#0552FF"
                            fontSize={isMobile ? 'x-large' : 'xx-large'}
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            {quantity} {selectedCurrency}
                        </Typography>
                    </Box>

                    <Box>
                        <Typography
                            alignContent="center"
                            justifyContent="center"
                            color="#9D9D9D"
                            fontSize={isMobile ? 'x-small' : 'small'}
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            DEPOSIT ADDRESS
                        </Typography>
                    </Box>

                    <Box mb={isMobile ? 2 : 4} >
                        <Typography
                            alignContent="center"
                            justifyContent="center"
                            color="#1B2030"
                            fontSize={isMobile ? 'x-small' : 'small'}
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            {depositAddress}
                        </Typography>
                    </Box>

                    <Button
                        size="large"
                        onClick={handleClick}
                        variant="contained"
                        sx={{
                            height: '45px',
                            borderRadius: '50px',
                            backgroundColor: '#0752FF',
                            whiteSpace: 'nowrap',
                            textTransform: 'none',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: '20px',
                            marginBottom: '-40px',
                        }}>
                        View you loans
                    </Button>
                </Box>

            </Grid>
            {
                isMobile ? <MobileMenuBar/> : null
            }
        </Grid>
    );
};
import './SignupPage.css'
import React, {FormEvent, useEffect, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {Button} from '@mui/material';
import {AutoPlayVideo} from '../../components/AutoPlayVideo';
import logo from '../../images/Bitlendr_transparent.svg';
import {signUp, confirmSignUp} from 'aws-amplify/auth';

const MOBILE_WIDTH_THRESHOLD = 910;

export const SignupPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [givenName, setGivenName] = useState<string>('');
    const [familyName, setFamilyName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [showConfirmationCodeField, setShowConfirmationCodeField] = useState<boolean>(false);
    const [confirmationCode, setConfirmationCode] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH_THRESHOLD);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= MOBILE_WIDTH_THRESHOLD);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const signUpHandler = async (e: FormEvent) => {
        e.preventDefault();

        try {
            const signUpResponse = await signUp({
                username: email,
                password,
                options: {
                    userAttributes: {
                        given_name: givenName,
                        family_name: familyName
                    }
                }
            });

            if (signUpResponse.isSignUpComplete) {
                navigate('/login');
            } else if (signUpResponse.nextStep.signUpStep === "CONFIRM_SIGN_UP") {
                console.log("Still need to confirm signup...");
                setShowConfirmationCodeField(true);
            } else {
                console.error("Could not signup!");
            }

            setError(null);
        } catch (error) {
            console.error(`Encountered the following error while signing up: [${JSON.stringify(error)}].`);
            // @ts-ignore
            setError(error.message);
        }
    };
    const confirmSignupHandler = async (e: FormEvent) => {
        e.preventDefault();

        try {
            const confirmSignUpResponse = await confirmSignUp({
                username: email,
                confirmationCode
            });

            if (confirmSignUpResponse.isSignUpComplete) {
                navigate('/login');
            } else {
                console.error("Could not confirm signup!");
            }

            setError(null);
        } catch (error) {
            console.error(`Encountered the following error while confirming signing up: [${JSON.stringify(error)}].`);
            // @ts-ignore
            setError(error.message);
        }
    };

    return (
        <>
            <main className="signup-main">
                {
                    !isMobile && (
                        <div style={{marginLeft: '10vw'}}>
                            <AutoPlayVideo height={400} loop muted playsInline/>
                        </div>
                    )
                }
                <div className={isMobile ? "mobile-signup-container" : "signup-container"}>
                    <img src={logo} alt="Bitlendr" style={{maxHeight: '40px', marginBottom: '50px'}}/>
                    <form>

                        {
                            !showConfirmationCodeField &&
                            <>
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="given-name" className={isMobile ? "mobile-label-string" : "label-string"}>Given name</label>
                                    </div>
                                    <input
                                        className={isMobile ? "mobile-input-field" : "input-field"}
                                        id="given-name"
                                        value={givenName}
                                        onChange={(e) => setGivenName(e.target.value)}
                                        required
                                        placeholder="Given name"
                                    />
                                </div>
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="family-name" className={isMobile ? "mobile-label-string" : "label-string"}>Family name</label>
                                    </div>
                                    <input
                                        className={isMobile ? "mobile-input-field" : "input-field"}
                                        id="family-name"
                                        value={familyName}
                                        onChange={(e) => setFamilyName(e.target.value)}
                                        required
                                        placeholder="Family name"
                                    />
                                </div>
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="email-address" className={isMobile ? "mobile-label-string" : "label-string"}>Email address</label>
                                    </div>
                                    <input
                                        className={isMobile ? "mobile-input-field" : "input-field"}
                                        type="email"
                                        id="email-address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        placeholder="Email address"
                                    />
                                </div>
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="password" className={isMobile ? "mobile-label-string" : "label-string"}>Password</label>
                                    </div>
                                    <input
                                        className={isMobile ? "mobile-input-field" : "input-field"}
                                        type="password"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                        placeholder="Password"
                                    />
                                </div>
                            </>
                        }

                        {
                            showConfirmationCodeField &&
                            <div className="form-group">
                                <div>
                                    <label htmlFor="password" className={isMobile ? "mobile-label-string" : "label-string"}>Confirmation Code</label>
                                </div>
                                <input
                                    className={isMobile ? "mobile-input-field" : "input-field"}
                                    type="password"
                                    id="confirmationCode"
                                    value={confirmationCode}
                                    onChange={(e) => setConfirmationCode(e.target.value)}
                                    required
                                    placeholder="Confirmation Code"
                                />
                            </div>
                        }

                        {
                            error &&
                            <p className="error-message">
                                {error}
                            </p>
                        }

                        <div className="mt-4">
                            {
                                !showConfirmationCodeField
                                    ? (
                                        <Button
                                            type="submit"
                                            onClick={signUpHandler}
                                            variant="contained"
                                            sx={{
                                                margin: 'auto',
                                                width: '100px',
                                                height: '45px',
                                                borderRadius: '50px',
                                                backgroundColor: '#1B2030',
                                                whiteSpace: 'nowrap',
                                                textTransform: 'none',
                                            }}
                                        >
                                            Sign up
                                        </Button>
                                    )
                                    : (
                                        <Button
                                            type="submit"
                                            onClick={confirmSignupHandler}
                                            variant="contained"
                                            sx={{
                                                margin: 'auto',
                                                width: '100px',
                                                height: '45px',
                                                borderRadius: '50px',
                                                backgroundColor: '#1B2030',
                                                whiteSpace: 'nowrap',
                                                textTransform: 'none',
                                            }}
                                        >
                                            Confirm
                                        </Button>
                                    )
                            }
                        </div>
                    </form>

                    <p className="message">
                        Already have an account?{' '}
                        <NavLink to="/login" className="link-login">
                            Sign in
                        </NavLink>
                    </p>
                </div>
            </main>
        </>
    );
};
import './Home.css';
import React, {useEffect, useState} from 'react';
import {InAppHeader} from '../../components/inAppHeader/InAppHeader';
import {HomePageCalculator} from '../../components/loanCalculatorExpanded/HomePageCalculator';
import {SideMenu, SideMenuItems} from '../../components/sideMenu/SideMenu';
import {Box, Container} from "@mui/material";

export const Home = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            minHeight="100vh"
            height="100%"
            sx={{
                justifyContent: 'center',
                backgroundColor: '#E5ECFB'
            }}
        >
            <Container>
                {
                    isMobile ? null : <SideMenu initialItem={SideMenuItems.CALCULATOR} />
                }
                <InAppHeader
                    title={'Loan calculator'}
                    subtitle={'Do you want to take another loan deposit?'}
                    isMobile={isMobile}
                />
                    <HomePageCalculator
                        sliderColorOverride="#1B2030"
                        sliderTextColorOverride="#1B2030"
                        labelTextColorOverride="#9D9D9D"
                        inputFieldBackgroundColorOverride="#FFF"
                        isMobile={isMobile}
                    />
            </Container>
        </Box>
    );
};

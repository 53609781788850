import './Login.css';
import React, {FormEvent, useEffect, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {FacebookButton} from '../../components/FacebookButton';
import {GoogleButton} from '../../components/GoogleButton';
import {Button} from '@mui/material';
import logo from '../../images/Bitlendr_transparent.svg';
import {AutoPlayVideo} from '../../components/AutoPlayVideo';
import {signInWithRedirect, signIn} from 'aws-amplify/auth';

const MOBILE_WIDTH_THRESHOLD = 910;

export const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH_THRESHOLD);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= MOBILE_WIDTH_THRESHOLD);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const loginHandler = async (event: FormEvent) => {
        event.preventDefault();
        try {
            const signInResponse = await signIn({
                username: email,
                password
            });
        } catch (error) {
            console.error(`Caught error while trying to log in: [${JSON.stringify(error)}].`);
            // @ts-ignore
            setError(error.message);
        }
    };

    const signInWithFacebook = async () => {
        console.log('Redirecting to Facebook Sign-in...');
        await signInWithRedirect();
    };

    const signInWithGoogle = async () => {
        console.log('Redirecting to Google Sign-in...');
        await signInWithRedirect({ provider: 'Google' });
    };

    return (
        <>
            <main className="login-main">
                {
                    !isMobile && (
                        <div style={{marginLeft: '10vw'}}>
                            <AutoPlayVideo height={400} loop muted playsInline/>
                        </div>
                    )

                }
                <div className={isMobile ? "mobile-login-container" : "login-container"}>
                    <img
                        src={logo}
                        alt="Bitlendr"
                        style={{maxHeight: '40px', marginBottom: '50px'}}
                        onClick={() => {
                            navigate('/');
                        }}
                    />
                    <form onSubmit={loginHandler}>
                        <div className="login-form-group">
                            <div>
                                <label htmlFor="email-address" className={isMobile ? "mobile-label-string" : "label-string"}>Email address</label>
                            </div>
                            <input
                                className={isMobile ? "mobile-input-field" : "input-field"}
                                id="email-address"
                                name="email"
                                type="email"
                                required
                                placeholder="Email address"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        <div className="login-form-group">
                            <div>
                                <label htmlFor="password" className={isMobile ? "mobile-label-string" : "label-string"}>Password</label>
                            </div>
                            <input
                                className={isMobile ? "mobile-input-field" : "input-field"}
                                id="password"
                                name="password"
                                type="password"
                                required
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        <div className="login-form-group">
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    margin: 'auto',
                                    width: '100px',
                                    height: '45px',
                                    borderRadius: '50px',
                                    backgroundColor: '#1B2030',
                                    whiteSpace: 'nowrap',
                                    textTransform: 'none',
                                }}>
                                Login
                            </Button>
                        </div>
                    </form>


                    {
                        error &&
                        <p className="error-message">
                            {error}
                        </p>
                    }

                    <p className="message">
                        Forgot your password?{' '}
                        <NavLink to="/forgot-password" className="link-reset">
                            Reset password
                        </NavLink>
                    </p>

                    <p className="message">Or sign in with:</p>

                    <div className="social-buttons">
                        {/*<FacebookButton onClick={signInWithFacebook} />*/}
                        <GoogleButton onClick={signInWithGoogle} />
                    </div>

                    <p className="message">
                        No account yet?{' '}
                        <NavLink to="/signup" className="link-reset">
                            Sign up
                        </NavLink>
                    </p>
                </div>
            </main>
        </>
    );
};

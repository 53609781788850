import React from 'react';
import { FaGoogle } from 'react-icons/fa';

interface GoogleButtonProps {
    onClick: () => void;
}
export const GoogleButton = ({ onClick }: GoogleButtonProps) => {
    return (
        <button style={{borderRadius: '20px'}} className="btn btn-danger d-flex align-items-center" onClick={onClick}>
            <FaGoogle/>
        </button>
    );
};

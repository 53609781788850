import {Box, Button, Grid} from "@mui/material";
import Logo from '../../images/Bitlendr_transparent.svg';
import React from "react";
import {FaCalculator, FaDollarSign, FaSignOutAlt} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {signOut} from "aws-amplify/auth";

const buttonStyle = {
    minWidth: '40px',
    padding: '10px',
    ":hover": {
        backgroundColor: '#1B2030',
    },
    ":focus": {
        backgroundColor: '#1B2030',
    },
};

export const MobileMenuBar = () => {
    const navigate = useNavigate();
    const navigateCalculator = () => {
        navigate('/home', {
            state: {
            }
        });
    };

    const navigateLoans = () => {
        navigate('/list', {
            state: {
            }
        });
    };

    const handleLogOut = async () => {
        console.log("Should sign out here!!!");
        await signOut({global: true});
        window.location.reload();
    }

    return (
        <Grid item xs={11}>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="20px"
                sx={{
                    background: '#0752FF',
                    padding: '15px',
                    borderRadius: '15px',
                }}
            >
                <Box
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <img src={Logo} alt={'Bitlendr'} style={{maxHeight: '12px', minWidth: '40px'}}/>
                    <Button sx={buttonStyle} onClick={navigateCalculator} >
                        <FaCalculator color="white" />
                    </Button>
                    <Button sx={buttonStyle} onClick={navigateLoans} >
                        <FaDollarSign color="white" />
                    </Button>
                    <Button sx={buttonStyle} onClick={handleLogOut} >
                        <FaSignOutAlt color="white"/>
                    </Button>
                </Box>
            </Box>
        </Grid>
    );
};
import './SettingsPage.css';
import React, {useEffect, useState} from 'react';
import {InAppHeader} from '../../components/inAppHeader/InAppHeader';
import {SideMenu, SideMenuItems} from '../../components/sideMenu/SideMenu';

export const SettingsPage = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="home-container">
            <SideMenu initialItem={SideMenuItems.SETTINGS} />
            <InAppHeader
                title="Profile settings"
                header=""
                subtitle=""
                isMobile={isMobile}
            />
            <div className="centered-container">

            </div>
        </div>
    );
};
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {SideMenu, SideMenuItems} from '../../components/sideMenu/SideMenu';
import {InAppHeader} from '../../components/inAppHeader/InAppHeader';
import {Receipt} from '../../components/receipt/Receipt';
import {Box, Container} from "@mui/material";

export const ReceiptPage = () => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            minHeight="100vh"
            height="100%"
            sx={{
                justifyContent: 'center',
                backgroundColor: '#E5ECFB'
            }}
        >
            <Container>
                {
                    isMobile ? null : <SideMenu initialItem={SideMenuItems.CALCULATOR} />
                }
                <InAppHeader
                    title={'Loan calculator'}
                    header="All good! 🎉"
                    subtitle=""
                    isMobile={isMobile}
                />
                <Receipt isMobile={isMobile}/>
            </Container>
        </Box>
    );
};
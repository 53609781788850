import {Box, Typography} from "@mui/material";
import {Status} from "../types";
import React from "react";

export const STATUS_PILL_COLOUR: {[key: string]: string} = {
    [Status.PENDING]: '#BB78D9',
    [Status.APPROVED]: '#84BF6E',
    [Status.REJECTED]: '#E27171',
};

export const STATUS_PILL_TEXT: {[key: string]: string} = {
    [Status.PENDING]: 'Pending',
    [Status.APPROVED]: 'Approved',
    [Status.REJECTED]: 'Rejected',
};

interface StatusPillProps {
    isMobile: boolean;
    text: string;
    backgroundColor: string;
}
const StatusPill = ({isMobile, text, backgroundColor}: StatusPillProps) => (
    <Box
        sx={{
            borderRadius: '20px',
            backgroundColor: backgroundColor,
        }}
        mb={isMobile ? 2 : 3}
    >
        <Typography
            ml={isMobile ? 1 : 2}
            mr={isMobile ? 1 : 2}
            mt={isMobile ? 0.5 : 1}
            mb={isMobile ? 0.5 : 1}
            alignSelf="center"
            justifySelf="center"
            color={'#FAFAFA'}
            fontSize="x-small"
            sx={{
                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
            }}
        >
            {text}
        </Typography>
    </Box>
);

interface LoanStatusProps {
    status: Status;
    isMobile: boolean;
}
export const LoanStatus = ({status, isMobile}: LoanStatusProps) => (
    <StatusPill isMobile={isMobile} text={STATUS_PILL_TEXT[status]} backgroundColor={STATUS_PILL_COLOUR[status]}/>
);
import React, { useRef, useEffect } from 'react';

interface AutoPlayVideoInterface {
    height?: number;
    loop?: boolean;
    playsInline?: boolean;
    muted?: boolean;
    style?: any;
}

export const AutoPlayVideo = ({
   height,
   loop,
   playsInline,
   muted,
   style = {}
}: AutoPlayVideoInterface) => {
    const src = "https://bitlendr-static-media.s3.eu-west-1.amazonaws.com/spinningCoin.mp4";
    const videoRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const video = videoRef.current;
                if (video) {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
// @ts-ignore
                            video.play();
                        } else {
// @ts-ignore
                            video.pause();
                        }
                    });
                }
            },
            {
                root: null, // viewport
                rootMargin: '0px',
                threshold: [0, 1.0] // Callback will trigger at 0% and 100% visibility
            }
        );

        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };
    }, [src]); // Re-run effect if src changes

    return (
        <video
            ref={videoRef}
            style={{ marginLeft: "100px", ...style }}
            height={height}
            src={src}
            muted={muted}
            loop={loop}
            playsInline={playsInline}
        />
    );
};

import React, {useCallback, useEffect, useState} from 'react';
import {SideMenu, SideMenuItems} from "../../components/sideMenu/SideMenu";
import {InAppHeader} from "../../components/inAppHeader/InAppHeader";
import {LoanListItem} from "../../components/loanListItem/LoanListItem";
import { generateClient } from 'aws-amplify/api';
import { listLoans } from '../../graphql/queries';
import {Status} from '../../components/types';
import {Backdrop, Box, CircularProgress, Container, Grid} from "@mui/material";
import {MobileMenuBar} from "../../components/menu/MobileMenuBar";

const client = generateClient();

export interface LoanItemData {
    id: string;
    loanAmount: string;
    loanCurrency: string;
    collateralAmount: string;
    collateralCryptoCurrency: string;
    interestAmount: string;
    interestCurrency: string;
    healthFactor: string;
    createdAt: string;
    updatedAt: string;
    owner: string;
    status: Status;
    beneficiaryIban?: string;
    beneficiarySwiftCode?: string;
    beneficiaryName?: string;
    beneficiaryAddress?: string;
    loanWalletAddress?: string;
    loanWalletPublicKey?: string;
}

interface ListLoansResponse {
    data: {
        listLoans: {
            items: LoanItemData[];
        };
    };
}

export const LoanList = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [needsMargin, setNeedsMargin] = useState(window.innerWidth <= 1350);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            setNeedsMargin(window.innerWidth <= 1350)
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [loans, setLoans] = useState<LoanItemData[]>([]);

    const fetchData = useCallback(async () => {
        try {
            const result: ListLoansResponse = await client.graphql({ query: listLoans, authMode: 'userPool' }) as ListLoansResponse;
            console.log(`Results are [${JSON.stringify(result.data.listLoans.items)}].`);
            setLoans(result.data.listLoans.items);
        } catch (error) {
            console.error(`Encountered Error: ${JSON.stringify(error)}`);
        }

        setIsLoading(false);
    }, [setLoans, setIsLoading]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            width="100%"
            minHeight="100vh"
            height="100%"
            sx={{
                justifyContent: 'center',
                backgroundColor: '#E5ECFB'
            }}
        >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Container>
                {
                    isMobile ? null : <SideMenu initialItem={SideMenuItems.LOANS} />
                }
                <InAppHeader
                    title={'My Loans'}
                    header=""
                    subtitle="This is an overview of your loan portfolio, offering you the flexibility to effortlessly settle existing loans, acquire new ones, and capitalize on your idle digital assets."
                    isMobile={isMobile}
                    mbOverrides={{
                        mobile: 4,
                        normal: 4
                    }}
                />
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    marginLeft={isMobile ? undefined : !needsMargin ? undefined : '70px'}
                    paddingRight={isMobile ? undefined : !needsMargin ? undefined : '90px'}
                >
                    {
                        // @ts-ignore
                        loans.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((loan) => (
                            <LoanListItem
                                id={loan.id}
                                date={loan.updatedAt}
                                loanAmount={Number(loan.loanAmount)}
                                loanCurrency={loan.loanCurrency}
                                collateralAmount={Number(loan.collateralAmount)}
                                collateralCryptoCurrency={loan.collateralCryptoCurrency}
                                interestAmount={Number(loan.interestAmount)}
                                interestCurrency={loan.interestCurrency}
                                healthFactor={Number(loan.healthFactor)}
                                status={loan.status}
                                loanWalletAddress={loan.loanWalletAddress}
                                loanWalletPublicKey={loan.loanWalletPublicKey}
                                isMobile={isMobile}
                            />
                        ))
                    }
                </Grid>
                {
                    !isMobile ? null : (
                        <Grid container spacing={2} justifyContent="center">
                            <MobileMenuBar/>
                        </Grid>
                    )
                }
            </Container>
        </Box>
    );
};
import './ForgotPasswordPage.css'
import React, {FormEvent, useEffect, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {Button} from '@mui/material';
import {AutoPlayVideo} from '../../components/AutoPlayVideo';
import logo from '../../images/Bitlendr_transparent.svg';
import {resetPassword, confirmResetPassword} from 'aws-amplify/auth';

const MOBILE_WIDTH_THRESHOLD = 910;

export const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string | null>(null);
    const [resetSent, setResetSent] = useState<boolean>(false);
    const [verificationCode, setVerificationCode] = useState<string | null>(null);
    const [newPassword, setNewPassword] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_WIDTH_THRESHOLD);
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= MOBILE_WIDTH_THRESHOLD);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleResetPassword = async (event: FormEvent) => {
        event.preventDefault();
        console.log(`Should be doing some stuff in here...email is: ${email}`);

        try {
            if (!email) {
             throw new Error('No email provided!');
            }
            const resetResponse = await resetPassword({
                username: email
            });

            switch (resetResponse.nextStep.resetPasswordStep) {
                case "CONFIRM_RESET_PASSWORD_WITH_CODE":
                    setResetSent(true);
                    break;
                case "DONE":
                    break;
            }

            setError(null);
        } catch (error) {
            console.log(`Caught error while trying to reset the password for user [${email}]. Error was: ${JSON.stringify(error)}`);
            // @ts-ignore
            setError(error.message);
        }
    };
    const handleConfirmResetPassword = async (event: FormEvent) => {
        event.preventDefault();
        console.log(`Should ALSO be doing some stuff in here...`);

        try {
            if (!email) {
                throw new Error('No email provided!');
            }
            if (!verificationCode) {
                throw new Error('No confirmation code provided!');
            }
            if (!newPassword) {
                throw new Error('No new password provided!');
            }
            await confirmResetPassword({
                username: email,
                confirmationCode: verificationCode,
                newPassword: newPassword
            });
            console.log(`Successfully reset the password for user [${email}].`);
            navigate('/login');
        } catch (error) {
            console.log(`Caught error while trying to confirm password reset for user [${email}]. Error was: ${JSON.stringify(error)}`);
            // @ts-ignore
            setError(error.message);
        }
    };

    return (
        <>
            <main className="forgot-password-main">
                {
                    !isMobile && (
                        <div style={{marginLeft: '10vw'}}>
                            <AutoPlayVideo height={400} loop muted playsInline/>
                        </div>
                    )
                }
                <div className={isMobile ? "mobile-forgot-password-container" : "forgot-password-container"}>
                <img
                        src={logo}
                        alt="Bitlendr"
                        style={{maxHeight: '40px', marginBottom: '50px'}}
                        onClick={() => {
                            navigate('/');
                        }}
                    />

                    {
                        !resetSent
                        ? (
                            <form onSubmit={handleResetPassword}>
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="email-address" className={isMobile ? "mobile-label-string" : "label-string"}>Email address</label>
                                    </div>
                                    <input
                                        className={isMobile ? "mobile-input-field" : "input-field"}
                                        id="email-address"
                                        name="email"
                                        type="email"
                                        required
                                        placeholder="Email address"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            margin: 'auto',
                                            width: '140px',
                                            height: '45px',
                                            borderRadius: '50px',
                                            backgroundColor: '#1B2030',
                                            whiteSpace: 'nowrap',
                                            textTransform: 'none',
                                        }}>
                                        Reset Password
                                    </Button>
                                </div>
                            </form>
                        )
                        : (
                            <form onSubmit={handleConfirmResetPassword}>
                                <div className="form-group">
                                    <div>
                                        <label htmlFor="verification-code" className={isMobile ? "mobile-label-string" : "label-string"}>Verification Code</label>
                                    </div>
                                    <input
                                        className={isMobile ? "mobile-input-field" : "input-field"}
                                        id="verification-code"
                                        name="verificationCode"
                                        required
                                        placeholder="Verification Code"
                                        onChange={(e) => setVerificationCode(e.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <div>
                                        <label htmlFor="new-password" className={isMobile ? "mobile-label-string" : "label-string"}>New Password</label>
                                    </div>
                                    <input
                                        className={isMobile ? "mobile-input-field" : "input-field"}
                                        id="new-password"
                                        name="newPassword"
                                        type="password"
                                        required
                                        placeholder="New Password"
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            margin: 'auto',
                                            width: '140px',
                                            height: '45px',
                                            borderRadius: '50px',
                                            backgroundColor: '#1B2030',
                                            whiteSpace: 'nowrap',
                                            textTransform: 'none',
                                        }}>
                                        Reset Password
                                    </Button>
                                </div>
                            </form>
                        )
                    }

                    {
                        error &&
                        <p className="error-message">
                            {error}
                        </p>
                    }

                    <p className="message">
                        Remembered your password?{' '}
                        <NavLink to="/login" className="link-login">
                            Sign in
                        </NavLink>
                    </p>
                </div>
            </main>
        </>
    );
};

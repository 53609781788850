import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import logo from '../images/Bitlendr_transparent.svg';
import { useNavigate } from "react-router-dom";

const styles = {
    menuContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '50px',
        padding: '0 20px',
        backgroundColor: 'transparent',
        position: 'absolute',
        marginTop: '7px',
        top: 0,
        left: 0
    },
    logoSection: {
        flex: 1,
    },
    logoImage: {
        maxHeight: '25px',
        maxWidth: '100%',
        height: 'auto'
    },
    linksSection: {
        flex: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    link: {
        textDecoration: 'none',
        color: '#E6ECFB',
        padding: '10px'
    },
    buttonSection: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    hamburgerButton: {
        fontSize: '28px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'transparent',
        border: 'none',
        color: '#E6ECFB',
    },
    mobileNav: {
        position: 'fixed',
        top: '60px',
        left: '2.5%', // centered with 95% width
        width: '95%',
        backgroundColor: '#2C3A47',
        padding: '10px',
        borderRadius: '8px', // rounded corners
        zIndex: 1,
        textAlign: 'center', // center the links text
    },
    mobileLink: {
        display: 'block',
        textDecoration: 'none',
        color: '#E6ECFB',
        padding: '15px',
        margin: '10px 0', // added vertical margin
        fontSize: '18px', // adjusted font size
    },
};

export const NavigationMenu = () => {
    const navigate = useNavigate();
    const [isMobileNavVisible, setMobileNavVisible] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => setIsMobileView(window.innerWidth < 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClick = () => {
        navigate('/home');
    };

    const toggleMobileNav = () => {
        setMobileNavVisible(!isMobileNavVisible);
    };

    return (
        <div
            // @ts-ignore
            style={styles.menuContainer}
        >
            {/* Logo Section */}
            <div style={styles.logoSection}>
                <img src={logo} alt="Bitlendr" style={styles.logoImage}/>
            </div>

            {isMobileView ? (
                // Mobile View
                <>
                    <button onClick={toggleMobileNav} style={styles.hamburgerButton}>
                        ☰
                    </button>
                    {isMobileNavVisible && (
                        <div
                            // @ts-ignore
                            style={styles.mobileNav}
                        >
                            {/* Links */}
                            {/*<a href="#" style={styles.mobileLink}>Articles</a>*/}
                            {/*<a href="#" style={styles.mobileLink}>Another menu</a>*/}
                            {/*<a href="#" style={styles.mobileLink}>New menu</a>*/}
                            {/*<a href="#" style={styles.mobileLink}>Legal</a>*/}
                            {/* Button */}
                            <Button onClick={handleClick} variant="contained">
                                Get money
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                // Desktop View
                <>
                    {/* Links Section */}
                    <div style={styles.linksSection}>
                        {/*<a href="#" style={styles.link}>Articles</a>*/}
                        {/*<a href="#" style={styles.link}>Another menu</a>*/}
                        {/*<a href="#" style={styles.link}>New menu</a>*/}
                        {/*<a href="#" style={styles.link}>Legal</a>*/}
                    </div>
                    {/* Button Section */}
                    <div style={styles.buttonSection}>
                        <Button onClick={handleClick} variant="contained" sx={{
                            backgroundColor: '#1B2030',
                            color: 'white',
                            borderRadius: '25px',
                            textTransform: 'none'
                        }}>
                            Get money
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

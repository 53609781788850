import { useEffect } from 'react';
import * as THREE from 'three';
import svg1 from "../../images/Digital_Assets_BITLENDR_1.svg";
import svg2 from "../../images/Digital_Assets_BITLENDR_2.svg";
import svg3 from "../../images/Digital_Assets_BITLENDR_3.svg";
import svg4 from "../../images/Digital_Assets_BITLENDR_4.svg";
import svg5 from "../../images/Digital_Assets_BITLENDR_5.svg";
import svg6 from "../../images/Digital_Assets_BITLENDR_6.svg";
import svg7 from "../../images/Digital_Assets_BITLENDR_7.svg";

export const BallsAnimation = (threejsContainerRef, isCategorySix, isMobile) => {
    useEffect(() => {
        if (!isCategorySix) return;
        const radiusMultiplier = isMobile? 0.7 : 1;
        const BALL_RADIUS = 0.1 * window.innerHeight * radiusMultiplier;
        const GRAVITY = 0.02;
        const FRICTION = 0.98;
        const SAFE_DISTANCE = 2 * BALL_RADIUS;
        const scene = new THREE.Scene();
        const camera = new THREE.OrthographicCamera(
            window.innerWidth / -2,
            window.innerWidth / 2,
            window.innerHeight / 2,
            window.innerHeight / -2,
            -500,
            1000
        );

        // Check if the ball overlaps with another ball or lower edge of the screen
        const isValidPosition = (position, otherBalls) => {
            for (let otherBall of otherBalls) {
                if (position.distanceTo(otherBall.position) < SAFE_DISTANCE) {
                    return false;
                }
            }

            // Check if the ball is too close to the lower edge
            if (position.y - BALL_RADIUS <= -window.innerHeight / 2) {
                return false;
            }

            return true;
        };

        const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setSize(window.innerWidth, window.innerHeight);

        threejsContainerRef.current.appendChild(renderer.domElement);

        const balls = [];
        const ballGeometry = new THREE.SphereGeometry(0.1 * window.innerHeight);

        // Array of your imported SVGs
        const svgs = [svg1, svg2, svg3, svg4, svg5, svg6, svg7];

        // Create promises for loading textures
        const promises = svgs.map(svg => new Promise((resolve) => {
            new THREE.TextureLoader().load(svg, resolve);
        }));

        Promise.all(promises).then((textures) => {
            textures.forEach((texture) => {
                // Create a sprite material with the loaded texture
                const spriteMaterial = new THREE.SpriteMaterial({ map: texture, transparent: true, opacity: 1 });

                // Create a sprite using the sprite material
                const sprite = new THREE.Sprite(spriteMaterial);

                // Set the size of the sprite (adjust as per your requirement)
                const SPRITE_SIZE = BALL_RADIUS * 2;
                sprite.scale.set(SPRITE_SIZE, SPRITE_SIZE, 1);

                sprite.material.rotation = Math.random() * Math.PI; // Random start rotation

                let validPosition = false;
                let attempts = 0;
                while (!validPosition && attempts < 1000) {
                    sprite.position.set(
                        THREE.MathUtils.randFloatSpread(window.innerWidth - BALL_RADIUS * 2),
                        THREE.MathUtils.randFloatSpread(window.innerHeight - BALL_RADIUS * 2),
                        0
                    );

                    if (isValidPosition(sprite.position, balls)) {
                        validPosition = true;
                    } else {
                        attempts++;
                    }
                }

                sprite.velocity = new THREE.Vector2(Math.random() * 3 - 1.5, Math.random() * 3 - 1.5);
                balls.push(sprite);
                scene.add(sprite);
            });

            const animate = () => {
                requestAnimationFrame(animate);

                balls.forEach((ball) => {
                    ball.position.x += ball.velocity.x;
                    ball.position.y += ball.velocity.y;

                    // Screen boundary collision
                    if (ball.position.x - BALL_RADIUS <= -window.innerWidth / 2 || ball.position.x + BALL_RADIUS >= window.innerWidth / 2) ball.velocity.x = -ball.velocity.x;
                    if (ball.position.y - BALL_RADIUS <= -window.innerHeight / 2 || ball.position.y + BALL_RADIUS >= window.innerHeight / 2) ball.velocity.y = -ball.velocity.y;

                    // Sprite to sprite collision
                    for (let otherBall of balls) {
                        if (otherBall === ball) continue;

                        const distance = new THREE.Vector2().subVectors(new THREE.Vector2(ball.position.x, ball.position.y), new THREE.Vector2(otherBall.position.x, otherBall.position.y)).length();
                        if (distance < BALL_RADIUS * 2) {
                            // Simple physics formula to reflect vectors upon collision
                            let normal = new THREE.Vector2(ball.position.x - otherBall.position.x, ball.position.y - otherBall.position.y).normalize();
                            let relativeVelocity = new THREE.Vector2(ball.velocity.x - otherBall.velocity.x, ball.velocity.y - otherBall.velocity.y);
                            let speed = relativeVelocity.dot(normal);

                            if (speed > 0) continue;

                            ball.velocity.x -= normal.x * speed;
                            ball.velocity.y -= normal.y * speed;
                            otherBall.velocity.x += normal.x * speed;
                            otherBall.velocity.y += normal.y * speed;
                        }
                    }
                });

                renderer.render(scene, camera);
            };

            animate();
        });

        return () => {
            renderer.dispose();
        };
    }, [isCategorySix, threejsContainerRef]); // Added dependencies
};
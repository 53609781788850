import './InAppHeader.css';
import React, {useCallback, useEffect, useState} from 'react';
import {fetchUserAttributes} from 'aws-amplify/auth';
import {Box, Grid, Typography} from "@mui/material";
import {listShareableCodes} from "../../graphql/queries";
import {generateClient} from "aws-amplify/api";
import {createShareableCode} from "../../graphql/mutations";

const client = generateClient();

enum ShareableCodeType {
    REFERRAL = 'referral',
}
interface ShareableCodeItemData {
    id: string;
    type: ShareableCodeType;
}

export interface ListShareableCodesResponse {
    data: {
        listShareableCodes: {
            items: ShareableCodeItemData[];
        }
    }
}

export interface InAppHeaderProps {
    title: string;
    header?: string;
    subtitle: string;
    isMobile: boolean;
    mbOverrides?: {
        normal?: number;
        mobile?: number;
    };
}

export const InAppHeader = ({
    title,
    header,
    subtitle,
    isMobile,
    mbOverrides
}: InAppHeaderProps) => {
    const [givenName, setGivenName] = useState('');
    const [referralCode, setReferralCode] = useState<string | null>(null);

    const getUserAttributes = async (): Promise<void> => {
        const userAttributes = await fetchUserAttributes();
        setGivenName(userAttributes.given_name ?? 'Unknown');
    };

    const getReferralCode = useCallback(async (): Promise<void> => {
        try {
            let listResult: ListShareableCodesResponse = await client.graphql({ query: listShareableCodes, authMode: 'userPool' }) as ListShareableCodesResponse;
            console.log(`Results are [${JSON.stringify(listResult.data.listShareableCodes.items)}].`);

            if (listResult.data.listShareableCodes.items.length === 0) {
                const createResponse = await client.graphql({
                    query: createShareableCode,
                    variables: {
                        input: {
                            type: ShareableCodeType.REFERRAL
                        }
                    },
                    authMode: 'userPool'
                });
                console.log(`Create response was: [${JSON.stringify(createResponse)}].`);

                listResult = await client.graphql({ query: listShareableCodes, authMode: 'userPool' }) as ListShareableCodesResponse;
            }

            setReferralCode(listResult.data.listShareableCodes.items[0].id);
        } catch (error) {
            console.error(`Encountered Error: ${JSON.stringify(error)}`);
        }
    }, []);

    useEffect(() => {
        console.log(`Executing effect!!!`);
        getUserAttributes();
        getReferralCode();
    }, []);

    return (
        <Grid container mb={isMobile ? (mbOverrides?.mobile ?? 0) : ((mbOverrides?.normal ?? 0) + 2)} spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={8} justifyContent="center" alignItems="center">
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="20px"
                    sx={{
                        padding: '10px',
                        borderRadius: '20px',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#1a202c',
                            borderRadius: '10px',
                            padding: '5px 20px',
                            color: 'white'
                        }}
                        mb={isMobile ? 0 : 1}
                    >
                        <span>{title}</span>
                    </Box>
                    <Typography
                        mb={isMobile ? 0 : 1}
                        justifyContent="left"
                        color={'#1a202c'}
                        fontSize={isMobile ? 'x-large' : 'xxx-large'}
                        sx={{
                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                        }}
                    >
                        {header ?? `Hey, ${givenName}! 👋`}
                    </Typography>
                    <Typography
                        mb={isMobile ? 0 : 1}
                        justifyContent="left"
                        color={'#1a202c'}
                        fontSize={isMobile ? 'small' : 'medium'}
                        sx={{
                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                        }}
                    >
                        {subtitle}
                    </Typography>
                    {
                        referralCode ? (
                            <Typography
                                mb={isMobile ? 0 : 1}
                                justifyContent="left"
                                color={'#1a202c'}
                                fontSize={isMobile ? 'x-small' : 'small'}
                                sx={{
                                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                }}
                            >
                                {`Referral code: ${referralCode}`}
                            </Typography>
                        ) : null
                    }
                </Box>
            </Grid>
        </Grid>
    );
};

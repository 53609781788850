// taken from: https://docs.google.com/spreadsheets/d/11PT5bj0MenN_kNNdwX2wcjBI_uymAa_JRifikFOuAcY/edit?gid=0#gid=0
const WALLETS: {[coinName: string]: string} = {
    'BTC': '3FnM7zu53cZTJsLj7Uk4xFeAa75gpumD38',
    'ETH': '0xc0F1dF8E134d490d48A7C176BFA5c8afC29ef0bc',
    'ARB': '0xc0F1dF8E134d490d48A7C176BFA5c8afC29ef0bc',
    'MATIC': '0xc0F1dF8E134d490d48A7C176BFA5c8afC29ef0bc',
    'XRP': 'rw2ciyaNshpHe7bCHo4bRWq6pqqynnWKQg',
    'ADA': 'addr1vx2a8apl8y0yq9hk8l8w5ljkngzyur2wt4rknfjulz8lw5q7yrnel',
    'SOL': 'FkQVD5Z8i7VPAXWMzAg3jTc5oJr66ordXKVGmqqky6Vd',
    'DOT': '15ab3KtduVxGxPkLyvcRqcFMR2x1L6Rdg7yqPDKf2psXbP6N'
};

export const getWalletAddressForCoin = (coinName: string): string => {
    const returnableWallet = WALLETS[coinName];
    return returnableWallet ?? WALLETS['BTC'];
};

import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    FormControl,
    Input,
    MenuItem,
    Select,
    SelectChangeEvent,
    Slider,
    Typography,
    Grid,
    Divider,
    Button,
    Tooltip,
    Backdrop,
    CircularProgress
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import { RxQuestionMarkCircled } from 'react-icons/rx';
import {
    convertExchangeRates,
    convertPriceQuotes,
    ExchangeRates,
    ListCryptoPriceQuotesResponse,
    ListCurrencyExchangeRatesResponse,
    PriceQuotes
} from '../../data/loanMetrics';
import {MobileMenuBar} from "../menu/MobileMenuBar";
import {onUpdateCryptoPriceQuote, onUpdateCurrencyExchangeRate} from "../../graphql/subscriptions";
import {throttle} from "../../utils/utils";
import {listCryptoPriceQuotes, listCurrencyExchangeRates} from "../../graphql/queries";
import {generateClient} from "aws-amplify/api";

const client = generateClient();

export interface HomePageCalculatorProps {
    inputFieldBackgroundColorOverride?: string;
    sliderColorOverride?: string;
    sliderTextColorOverride?: string;
    labelTextColorOverride?: string;
    buttonTextOverride?: string;
    isMobile: boolean
}

export const HomePageCalculator = ({
    inputFieldBackgroundColorOverride,
    sliderColorOverride,
    sliderTextColorOverride,
    labelTextColorOverride,
    buttonTextOverride,
    isMobile,
}: HomePageCalculatorProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedLTV, setSelectedLTV] = useState<number>(0.8);
    const [exchangeRates, setExchangeRates] = useState<ExchangeRates>({});
    const [priceQuotes, setPriceQuotes] = useState<PriceQuotes>({});
    const [cryptoAmount, setCryptoAmount] = useState<number>(0); // Added state for crypto amount
    const [selectedCurrency, setSelectedCurrency] = useState<string>('USD');
    const [selectedCrypto, setSelectedCrypto] = useState<string>('BTC');
    const [quantity, setQuantity] = useState<number>(0);
    const navigate = useNavigate();

    const handleCalculateBtnClick = () => {
        navigate('/onboard', {
            state: {
                cryptoAmount,
                quantity,
                selectedCrypto,
                selectedCurrency,
                selectedLTV
            }
        });
    };

    const handleCurrencyChange = (event: SelectChangeEvent) => {
        setSelectedCurrency(event.target.value);
        const newCurrencyValue = (cryptoAmount * priceQuotes[selectedCrypto] * exchangeRates[event.target.value] * selectedLTV).toFixed(2);
        setQuantity(Number(newCurrencyValue));
    };

    const handleCurrencyAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQuantity = Number(event.target.value);
        setQuantity(newQuantity);
        const newCryptoValue = (newQuantity *  ( 1 / selectedLTV ) / (priceQuotes[selectedCrypto] * exchangeRates[selectedCurrency])).toFixed(2);
        setCryptoAmount(Number(newCryptoValue));
    };

    const handleCryptoChange = (event: SelectChangeEvent) => {
        setSelectedCrypto(event.target.value);
        const newCurrencyValue = (cryptoAmount * priceQuotes[event.target.value] * exchangeRates[selectedCurrency] * selectedLTV ).toFixed(2);
        setQuantity(Number(newCurrencyValue));
    };

    const handleCryptoAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newCryptoAmount = Number(event.target.value);
        setCryptoAmount(newCryptoAmount);
        const newCurrencyValue = (newCryptoAmount * priceQuotes[selectedCrypto] * exchangeRates[selectedCurrency] * selectedLTV).toFixed(2);
        setQuantity(Number(newCurrencyValue));
    };

    const handleLTVChange = (value: number | number[]) => {
        const newSelectedLTV = Array.isArray(value) ? value[0] : value;
        setSelectedLTV(newSelectedLTV);
        const newCurrencyValue = (cryptoAmount * priceQuotes[selectedCrypto] * exchangeRates[selectedCurrency] * newSelectedLTV).toFixed(2);
        setQuantity(Number(newCurrencyValue));
    };

    const updatePriceQuotes = useCallback(throttle(async () => {
        try {
            const cryptoPriceQuotesResponse: ListCryptoPriceQuotesResponse = await client.graphql({
                query: listCryptoPriceQuotes,
                authMode: 'userPool'
            }) as ListCryptoPriceQuotesResponse;
            setPriceQuotes(convertPriceQuotes(cryptoPriceQuotesResponse.data.listCryptoPriceQuotes.items));
        } catch (error) {
            console.error(`Encountered Error: ${JSON.stringify(error)}`);
        }
    }, 10000), []);

    const updateExchangeRates = useCallback(throttle(async () => {
        try {
            const currencyExchangeRatesResponse: ListCurrencyExchangeRatesResponse = await client.graphql({
                query: listCurrencyExchangeRates,
                authMode: 'userPool'
            }) as ListCurrencyExchangeRatesResponse;
            setExchangeRates(convertExchangeRates(currencyExchangeRatesResponse.data.listCurrencyExchangeRates.items));
        } catch (error) {
            console.error(`Encountered Error: ${JSON.stringify(error)}`);
        }
    }, 15000), []);

    const loadData = useCallback(async () => {
        try {
            const cryptoPriceQuotesResponse: ListCryptoPriceQuotesResponse = await client.graphql({
                query: listCryptoPriceQuotes,
                authMode: 'userPool'
            }) as ListCryptoPriceQuotesResponse;
            setPriceQuotes(convertPriceQuotes(cryptoPriceQuotesResponse.data.listCryptoPriceQuotes.items));

            const exchangeRatesResponse: ListCurrencyExchangeRatesResponse = await client.graphql({
                query: listCurrencyExchangeRates,
                authMode: 'userPool'
            }) as ListCurrencyExchangeRatesResponse;
            setExchangeRates(convertExchangeRates(exchangeRatesResponse.data.listCurrencyExchangeRates.items));
        } catch (error) {
            console.error(`Encountered Error: ${JSON.stringify(error)}`);
        }
        setCryptoAmount(1);

        setIsLoading(false);
    }, []);

    useEffect(() => {
        setQuantity(
            Number(
                Number(
                    priceQuotes[selectedCrypto] * exchangeRates[selectedCurrency] * selectedLTV
                ).toFixed(2)
            )
        );
    }, [selectedCurrency, selectedCrypto, priceQuotes, exchangeRates, selectedLTV]);

    useEffect(() => {
        loadData();

        // @ts-ignore
        const priceQuoteUpdateSub = client.graphql({ query: onUpdateCryptoPriceQuote, authMode: "userPool" }).subscribe({
            // @ts-ignore
            next: ({ data }) => {
                updatePriceQuotes();
            },
            // @ts-ignore
            error: (error) => console.error(`Error from crypto price quote subscriber: ${error}.`),
        });
        // @ts-ignore
        const exchangeRateUpdateSub = client.graphql({ query: onUpdateCurrencyExchangeRate, authMode: "userPool" }).subscribe({
            // @ts-ignore
            next: ({ data }) => {
                updateExchangeRates();
            },
            // @ts-ignore
            error: (error) => console.error(`Error from currency exchange rate subscriber: ${error}.`),
        });

        return () => {
            priceQuoteUpdateSub.unsubscribe();
            exchangeRateUpdateSub.unsubscribe();
        };
    }, []);

    return (
        <Grid container spacing={2} justifyContent="center" mb={isMobile ? 0 : 4}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid item xs={isMobile ? 11 : 8}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="20px"
                    sx={{
                        background: '#FAFAFA',
                        padding: '20px',
                        borderRadius: '20px',
                    }}
                >
                    {/* INPUTS */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        width="90%"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                mb={isMobile ? 0 : 1}
                                mt={isMobile ? 0 : 1}
                                justifyContent="left"
                                color={labelTextColorOverride ?? '#FAFAFA'}
                                fontSize='medium'
                                sx={{
                                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                }}
                            >
                                For
                            </Typography>
                            <FormControl
                                variant="filled"
                                sx={{
                                    width: '100%',
                                    borderRadius: '10px',
                                    border: 'solid 1px #9D9D9D',
                                    overflow: 'hidden',
                                    backgroundColor: inputFieldBackgroundColorOverride ?? '#EBEBEB'
                                }}
                            >
                                <Box display="flex" margin={0.5} >
                                    <Input
                                        type="number"
                                        value={cryptoAmount}
                                        onChange={handleCryptoAmountChange}
                                        disableUnderline
                                        sx={{
                                            flex: 1,
                                            marginLeft: '14px',
                                            '&:focus': {
                                                outline: 'none', // Remove the focus outline
                                            },
                                            '.MuiInputBase-input': {
                                                backgroundColor: inputFieldBackgroundColorOverride ?? '#EBEBEB',
                                                fontWeight: 'bold', // Make the text bold
                                                marginTop: '4px',
                                            },
                                        }}
                                    />
                                    <Select
                                        value={selectedCrypto}
                                        onChange={handleCryptoChange}
                                        onOpen={() => { console.log('On open...'); }}
                                        onClose={() => { console.log('On close...'); }}
                                        disableUnderline
                                        sx={{
                                            flex: '0 0 auto',
                                            minWidth: '80px',
                                            maxWidth: '85px',
                                            background: '#CFDAF2',
                                            color: '#0652ff',
                                            alignItems: 'center',
                                            borderRadius: '10px',
                                            '.MuiInputBase-input': {
                                                marginTop: '-14px',
                                                '&:hover': {
                                                    outline: 'none',
                                                },
                                            },
                                        }}
                                    >
                                        {Object.keys(priceQuotes).map((crypto) => (
                                            <MenuItem key={crypto} value={crypto}>
                                                {crypto}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                        width="90%"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                mt={isMobile ? 1 : 3}
                                mb={isMobile ? 0 : 1}
                                justifyContent="left"
                                color={labelTextColorOverride ?? '#FAFAFA'}
                                fontSize='medium'
                                sx={{
                                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                }}
                            >
                                You will get
                            </Typography>
                            <FormControl
                                variant="filled"
                                sx={{
                                    width: '100%',
                                    borderRadius: '10px',
                                    border: 'solid 1px #9D9D9D',
                                    overflow: 'hidden',
                                    backgroundColor: inputFieldBackgroundColorOverride ?? '#EBEBEB'
                                }}
                            >
                                <Box display="flex" margin={0.5} >
                                    <Input
                                        type="number"
                                        value={quantity}
                                        onChange={handleCurrencyAmountChange}
                                        disableUnderline
                                        sx={{
                                            flex: 1,
                                            marginLeft: '14px',
                                            '&:focus': {
                                                outline: 'none', // Remove the focus outline
                                            },
                                            '.MuiInputBase-input': {
                                                backgroundColor: inputFieldBackgroundColorOverride ?? '#EBEBEB',
                                                fontWeight: 'bold', // Make the text bold
                                                marginTop: '4px',
                                            },
                                        }}
                                    />
                                    <Select
                                        value={selectedCurrency}
                                        onChange={handleCurrencyChange}
                                        onOpen={() => { console.log('On open...'); }}
                                        onClose={() => { console.log('On close...'); }}
                                        disableUnderline
                                        sx={{
                                            flex: '0 0 auto',
                                            minWidth: '80px',
                                            maxWidth: '85px',
                                            background: '#CFDAF2',
                                            color: '#0652ff',
                                            alignItems: 'center',
                                            borderRadius: '10px',
                                            '.MuiInputBase-input': {
                                                marginTop: '-14px',
                                                '&:hover': {
                                                    outline: 'none',
                                                },
                                            },
                                        }}
                                    >
                                        {Object.keys(exchangeRates).map((currency) => (
                                            <MenuItem key={currency} value={currency}>
                                                {currency}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            </FormControl>
                        </Box>
                    </Box>

                    {/* SLIDER */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        width="90%"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                        >
                            <Typography
                                mt={isMobile ? 3 : 4}
                                justifyContent="left"
                                color={labelTextColorOverride ?? '#FAFAFA'}
                                fontSize='medium'
                                sx={{
                                    fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                }}
                            >
                                Loan to value
                            </Typography>
                            <Slider
                                marks={[{value: 0.5, label: '50%'},{value: 0.6, label: '60%'},{value: 0.7, label: '70%'},{value: 0.8, label: '80%'}]}
                                min={0.5}
                                max={0.8}
                                step={0.1}
                                valueLabelDisplay="off"
                                value={selectedLTV}
                                onChange={(event, newValue) => handleLTVChange(newValue)}
                                sx={{
                                    marginBottom: '60px',
                                    width: '96.7%',
                                    color: sliderColorOverride ?? '#CFDAF2',
                                    '& .MuiSlider-mark': {
                                        width: '16px',
                                        height: '16px',
                                        borderRadius: '50%',
                                        backgroundColor: 'currentcolor',
                                    },
                                    '& .MuiSlider-thumb': {
                                        marginLeft: '6px',
                                        width: '18px',
                                        height: '18px',
                                        borderRadius: '50%',
                                        backgroundColor: '#FFFFFF',
                                        border: '3px solid black',
                                        '&::before': {
                                            content: '""',
                                            display: 'block',
                                            width: '8px',
                                            height: '8px',
                                            borderRadius: '50%',
                                            backgroundColor: '#0752FF',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        },
                                        '&:hover, &.Mui-focusVisible': {
                                            boxShadow: 'inherit',
                                        }
                                    },
                                    '& .MuiSlider-markLabel': {
                                        top: '44px',
                                        color: sliderTextColorOverride ?? '#FFFFFF',
                                        marginLeft: '10px'
                                    },
                                }}
                            />
                        </Box>
                    </Box>

                    {/* DIVIDER */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        width="95%"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                        >
                            <Divider />
                        </Box>
                    </Box>

                    {/* Bottom texts */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        width="90%"
                    >
                        <Typography
                            mt={2}
                            color={labelTextColorOverride ?? '#FAFAFA'}
                            fontSize='x-small'
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            LOAN TERM
                        </Typography>
                        <Typography
                            mt={2}
                            color={labelTextColorOverride ?? '#FAFAFA'}
                            fontSize="x-small"
                            fontWeight="bold"
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            Unlimited
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        width="90%"
                    >
                        <Typography
                            mt={1}
                            color={labelTextColorOverride ?? '#FAFAFA'}
                            fontSize="x-small"
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            APY
                            <Tooltip title="Annual percentage yield">
                                <span>
                                    <RxQuestionMarkCircled style={{marginLeft: '5px'}}/>
                                </span>
                            </Tooltip>
                        </Typography>
                        <Typography
                            mt={1}
                            color={labelTextColorOverride ?? '#FAFAFA'}
                            fontSize="x-small"
                            fontWeight="bold"
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            8%
                        </Typography>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        width="90%"
                    >
                        <Typography
                            mt={1}
                            color={labelTextColorOverride ?? '#FAFAFA'}
                            fontSize="x-small"
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            WEEKLY INTEREST
                        </Typography>
                        <Typography
                            mt={1}
                            color={labelTextColorOverride ?? '#FAFAFA'}
                            fontSize="x-small"
                            fontWeight="bold"
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            {Number(quantity*0.08/52).toFixed(0)}USD
                        </Typography>
                    </Box>

                    <Button
                        size="large"
                        onClick={handleCalculateBtnClick}
                        variant="contained"
                        sx={{
                            height: '45px',
                            borderRadius: '50px',
                            backgroundColor: isMobile ? '#1a202c' : '#0752FF',
                            whiteSpace: 'nowrap',
                            textTransform: 'none',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginTop: '20px',
                            marginBottom: '-40px',
                        }}>
                        {buttonTextOverride ?? 'Calculate'}
                    </Button>
                </Box>
            </Grid>
            {
                isMobile ? <MobileMenuBar/> : null
            }
        </Grid>
    );
};
import './OnboardingForm.css';
import React, {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {
    Box,
    Button,
    FormControl,
    Grid,
    Input,
    Typography,
} from "@mui/material";
import {MobileMenuBar} from "../menu/MobileMenuBar";
import {listShareableCodes} from "../../graphql/queries";
import {ListShareableCodesResponse} from "../inAppHeader/InAppHeader";
import {generateClient, post} from "aws-amplify/api";

const client = generateClient();

export enum PaymentMethods {
    BANKING = 'banking',
    REVOLUT = 'revolut'
}

export enum OnboardingFormFields {
    IBAN = 'iban',
    REVOLUT_USERNAME = 'revolutUsername',
    BENEFICIARY_NAME = 'beneficiaryName',
    BENEFICIARY_ADDRESS = 'beneficiaryAddress',
    SWIFT_CODE = 'swiftCode',
    REFERRAL_CODE = 'referralCode',
}

type OnboardingFormFieldErrors = Map<OnboardingFormFields, string>;

interface OnboardingFormProps {
    isMobile: boolean;
    labelTextColor?: string;
    inputFieldBackgroundColor?: string;
    topBoxMainLabelsColor?: string;
    topBoxSecondaryLabelsColor?: string;
}

export const OnboardingForm = ({
    isMobile,
    labelTextColor,
    inputFieldBackgroundColor,
    topBoxMainLabelsColor,
    topBoxSecondaryLabelsColor
}: OnboardingFormProps) => {
    const [ownReferralCode, setOwnReferralCode] = useState<string | null>(null);
    const getOwnReferralCode = useCallback(async ()=> {
        let listResult: ListShareableCodesResponse = await client.graphql({ query: listShareableCodes, authMode: 'userPool' }) as ListShareableCodesResponse;
        console.log(`Results are [${JSON.stringify(listResult.data.listShareableCodes.items)}].`);
        setOwnReferralCode(listResult.data.listShareableCodes.items[0].id);
    }, []);

    const [iban, setIban] = useState<string>('RO09TREZ00222210310XXXXX');
    const [revolutUsername, setRevolutUsername] = useState<string>('anbc54');
    const [beneficiaryName, setBeneficiaryName] = useState<string>('Martin Scorsese');
    const [beneficiaryAddress, setBeneficiaryAddress] = useState<string>('Rue du Pain, No. 01, Ap. 02, Vienna');
    const [swiftCode, setSwiftCode] = useState<string>('ATBTRL22VIE');
    const [referralCode, setReferralCode] = useState<string | undefined>(undefined);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethods>(PaymentMethods.BANKING);
    const [errors, setErrors] = useState<OnboardingFormFieldErrors>(new Map());

    const navigate = useNavigate();
    const location = useLocation();
    const { cryptoAmount, quantity, selectedCrypto, selectedCurrency } = location.state || {};

    useEffect(() => {
        getOwnReferralCode();
    }, []);

    const validateField = async (name: OnboardingFormFields, value: string) => {
        let errorMsg = '';
        switch (name) {
            case OnboardingFormFields.IBAN:
                if (!value) errorMsg = 'IBAN is required';
                else if (!/^[A-Z]{2}\d{2}[A-Z0-9]{4}\d{7}([A-Z0-9]?){0,16}$/.test(value)) errorMsg = 'Invalid IBAN format';
                break;
            case OnboardingFormFields.REVOLUT_USERNAME:
                if (!value) errorMsg = 'Revolut Username is required';
                else if (!/^[a-zA-Z0-9_.]+$/.test(value)) errorMsg = 'Invalid Username format';
                break;
            case OnboardingFormFields.BENEFICIARY_NAME:
                if (!value) errorMsg = 'Beneficiary Name is required';
                else if (!/^[a-zA-Z\s,.'-]+$/.test(value)) errorMsg = 'Invalid Name format';
                break;
            case OnboardingFormFields.BENEFICIARY_ADDRESS:
                if (!value) errorMsg = 'Beneficiary Address is required';
                // Optional: Add regex for address validation if needed
                break;
            case OnboardingFormFields.SWIFT_CODE:
                if (!value) errorMsg = 'SWIFT Code is required';
                else if (!/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value)) errorMsg = 'Invalid SWIFT Code format';
                break;
            case OnboardingFormFields.REFERRAL_CODE:
                if (ownReferralCode !== null && ownReferralCode === value) {
                    errorMsg = "Can not use your own referral code!"
                } else {
                    const validationOperation = await post({
                        apiName: 'validateCode',
                        path:'/validateCode',
                        options: {
                            queryParams: {
                                code: value
                            }
                        }
                    });

                    const validationResult = await validationOperation.response;
                    const validationResponse = await validationResult.body.json() as unknown as { isValid: boolean; };

                    console.log(`Validation response is: [${JSON.stringify(validationResponse)}].`);
                    if (!validationResponse.isValid) {
                        errorMsg = "The provided referral code does not exist!"
                    }
                }
                break;
            default:
                console.error(`Unexpected OnboardingFormField: ${name}`);
                break;
        }
        if (errorMsg !== '') {
            setErrors(new Map([
                ...Array.from(errors.entries()),
                [name, errorMsg]
            ]));
        } else {
            setErrors(new Map([
                ...Array.from(errors.entries()).filter(([fieldName, fieldError]) => fieldName !== name)
            ]))
        }
        return errorMsg === '';
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        // Set value
        switch (id) {
            case OnboardingFormFields.IBAN:
                console.log('ibam');
                setIban(value);
                break;
            case OnboardingFormFields.REVOLUT_USERNAME:
                setRevolutUsername(value);
                break;
            case OnboardingFormFields.BENEFICIARY_NAME:
                setBeneficiaryName(value);
                break;
            case OnboardingFormFields.BENEFICIARY_ADDRESS:
                setBeneficiaryAddress(value);
                break;
            case OnboardingFormFields.SWIFT_CODE:
                setSwiftCode(value);
                break;
            case OnboardingFormFields.REFERRAL_CODE:
                setReferralCode(value);
                break;
            default:
                console.error(`Unexpected OnboardingFormField: ${id}`);
                break;
        }
        // Validate field
        validateField(id as OnboardingFormFields, value);
    };

    const handleClickBack = () => {
        navigate('/home', {
            state: {
            }
        });
    };

    const handleClickReview = async () => {
        // Validate all fields before navigating
        const isValidIban = await validateField(OnboardingFormFields.IBAN, iban);
        const isValidRevolutUsername = await validateField(OnboardingFormFields.REVOLUT_USERNAME, revolutUsername);
        const isValidBeneficiaryName = await validateField(OnboardingFormFields.BENEFICIARY_NAME, beneficiaryName);
        const isValidBeneficiaryAddress = await validateField(OnboardingFormFields.BENEFICIARY_ADDRESS, beneficiaryAddress);
        const isValidSwiftCode = await validateField(OnboardingFormFields.SWIFT_CODE, swiftCode);
        const isValidReferralCode = referralCode === undefined || await validateField(OnboardingFormFields.REFERRAL_CODE, referralCode);

        if (isValidIban && isValidRevolutUsername && isValidBeneficiaryName && isValidBeneficiaryAddress && isValidSwiftCode && isValidReferralCode) {
            navigate('/review', {
                state: {
                    ...location.state,
                    paymentMethod,
                    iban,
                    revolutUsername,
                    beneficiaryName,
                    beneficiaryAddress,
                    swiftCode,
                    referralCode
                },
            });
        }
    };

    const handlePaymentMethodChange = (method: PaymentMethods) => {
        setPaymentMethod(method);
    };


    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={isMobile ? 11 : 8}>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="20px"
                    sx={{
                        background: '#FAFAFA',
                        padding: '20px',
                        borderRadius: '20px',
                    }}
                >
                    {/* TOP BOX */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        width="90%"
                        sx={{
                            marginTop: isMobile ? '-64px' : '-74px'
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                            sx={{
                                backgroundColor: '#1B2030',
                                borderRadius: '10px',
                                padding: isMobile ? '16px' : '18px'
                            }}
                        >
                            <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                            >
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    ml="auto"
                                    mr="auto"
                                >
                                    <Typography
                                        alignSelf="center"
                                        color={topBoxMainLabelsColor ?? '#FAFAFA'}
                                        fontSize={isMobile ? 'xx-small' : 'small'}
                                        sx={{
                                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                        }}
                                    >
                                        FOR
                                    </Typography>
                                    <Typography
                                        mt={1}
                                        alignSelf="center"
                                        color={topBoxMainLabelsColor ?? '#FAFAFA'}
                                        fontSize={isMobile ? 'medium' : 'large'}
                                        fontWeight="bold"
                                        sx={{
                                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                        }}
                                    >
                                        {cryptoAmount} {selectedCrypto}
                                    </Typography>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    ml="auto"
                                    mr="auto"
                                >
                                    <Typography
                                        alignSelf="center"
                                        color={topBoxMainLabelsColor ?? '#FAFAFA'}
                                        fontSize={isMobile ? 'xx-small' : 'small'}
                                        sx={{
                                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                        }}
                                    >
                                        YOU WILL GET
                                    </Typography>
                                    <Typography
                                        mt={1}
                                        alignSelf="center"
                                        color={topBoxSecondaryLabelsColor ?? '#0552FF'}
                                        fontSize={isMobile ? 'medium' : 'large'}
                                        fontWeight="bold"
                                        sx={{
                                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                        }}
                                    >
                                        {quantity} {selectedCurrency}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {/* IBAN */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        width="90%"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                        >
                            <FormControl
                                variant="filled"
                                sx={{
                                    width: '100%',
                                    borderRadius: '10px',
                                    border: 'solid 1px #9D9D9D',
                                    backgroundColor: inputFieldBackgroundColor ?? '#FFFFFF',
                                    marginTop: '20px',
                                }}
                            >

                                <Typography
                                    justifyContent="left"
                                    color={labelTextColor ?? ''}
                                    fontSize='small'
                                    sx={{
                                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                        margin: '-13px 0 0 13px',
                                        borderRadius: '10px',
                                        background: '#FFFFFF',
                                        padding: '3px',
                                        display: 'inline-block',
                                        width: 'fit-content'
                                    }}
                                >
                                    IBAN*
                                </Typography>
                                <Box display="flex">
                                    <Input
                                        id={OnboardingFormFields.IBAN}
                                        type="text"
                                        value={iban}
                                        disableUnderline
                                        onChange={handleChange}
                                        sx={{
                                            flex: 1,
                                            marginLeft: '14px',
                                            '.MuiInputBase-input': {
                                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                            },
                                        }}
                                    />
                                </Box>
                            </FormControl>
                        </Box>
                    </Box>

                    {/* BENEFICIARY NAME */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        width="90%"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                        >
                            <FormControl
                                variant="filled"
                                sx={{
                                    width: '100%',
                                    borderRadius: '10px',
                                    border: 'solid 1px #9D9D9D',
                                    backgroundColor: inputFieldBackgroundColor ?? '#FFFFFF',
                                    marginTop: '20px',
                                }}
                            >

                                <Typography
                                    justifyContent="left"
                                    color={labelTextColor ?? ''}
                                    fontSize='small'
                                    sx={{
                                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                        margin: '-13px 0 0 13px',
                                        borderRadius: '10px',
                                        background: '#FFFFFF',
                                        padding: '3px',
                                        display: 'inline-block',
                                        width: 'fit-content'
                                    }}
                                >
                                    BENEFICIARY NAME*
                                </Typography>
                                <Box display="flex">
                                    <Input
                                        id={OnboardingFormFields.BENEFICIARY_NAME}
                                        type="text"
                                        value={beneficiaryName}
                                        disableUnderline
                                        onChange={handleChange}
                                        sx={{
                                            flex: 1,
                                            marginLeft: '14px',
                                            '.MuiInputBase-input': {
                                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                            },
                                        }}
                                    />
                                </Box>
                            </FormControl>
                        </Box>
                    </Box>

                    {/* BENEFICIARY ADDRESS */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        width="90%"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                        >
                            <FormControl
                                variant="filled"
                                sx={{
                                    width: '100%',
                                    borderRadius: '10px',
                                    border: 'solid 1px #9D9D9D',
                                    backgroundColor: inputFieldBackgroundColor ?? '#FFFFFF',
                                    marginTop: '20px',
                                }}
                            >

                                <Typography
                                    justifyContent="left"
                                    color={labelTextColor ?? ''}
                                    fontSize='small'
                                    sx={{
                                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                        margin: '-13px 0 0 13px',
                                        borderRadius: '10px',
                                        background: '#FFFFFF',
                                        padding: '3px',
                                        display: 'inline-block',
                                        width: 'fit-content'
                                    }}
                                >
                                    BENEFICIARY ADDRESS*
                                </Typography>
                                <Box display="flex">
                                    <Input
                                        id={OnboardingFormFields.BENEFICIARY_ADDRESS}
                                        type="text"
                                        value={beneficiaryAddress}
                                        disableUnderline
                                        onChange={handleChange}
                                        sx={{
                                            flex: 1,
                                            marginLeft: '14px',
                                            '.MuiInputBase-input': {
                                                backgroundColor: inputFieldBackgroundColor ?? '',
                                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                            },
                                        }}
                                    />
                                </Box>
                            </FormControl>
                        </Box>
                    </Box>

                    {/* SWIFT */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        width="90%"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                        >
                            <FormControl
                                variant="filled"
                                sx={{
                                    width: '100%',
                                    borderRadius: '10px',
                                    border: 'solid 1px #9D9D9D',
                                    backgroundColor: inputFieldBackgroundColor ?? '#FFFFFF',
                                    marginTop: '20px',
                                }}
                            >

                                <Typography
                                    justifyContent="left"
                                    color={labelTextColor ?? ''}
                                    fontSize='small'
                                    sx={{
                                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                        margin: '-13px 0 0 13px',
                                        borderRadius: '10px',
                                        background: '#FFFFFF',
                                        padding: '3px',
                                        display: 'inline-block',
                                        width: 'fit-content'
                                    }}
                                >
                                    SWIFT*
                                </Typography>
                                <Box display="flex">
                                    <Input
                                        id={OnboardingFormFields.SWIFT_CODE}
                                        type="text"
                                        value={swiftCode}
                                        disableUnderline
                                        onChange={handleChange}
                                        sx={{
                                            flex: 1,
                                            marginLeft: '14px',
                                            '.MuiInputBase-input': {
                                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                            },
                                        }}
                                    />
                                </Box>
                            </FormControl>
                        </Box>
                    </Box>

                    {/* REFERRAL CODE */}
                    <Box
                        display="flex"
                        flexDirection="row"
                        width="90%"
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            width="100%"
                        >
                            <FormControl
                                variant="filled"
                                sx={{
                                    width: '100%',
                                    borderRadius: '10px',
                                    border: 'solid 1px #9D9D9D',
                                    backgroundColor: inputFieldBackgroundColor ?? '#FFFFFF',
                                    marginTop: '20px',
                                }}
                            >

                                <Typography
                                    justifyContent="left"
                                    color={labelTextColor ?? ''}
                                    fontSize='small'
                                    sx={{
                                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                        margin: '-13px 0 0 13px',
                                        borderRadius: '10px',
                                        background: '#FFFFFF',
                                        padding: '3px',
                                        display: 'inline-block',
                                        width: 'fit-content'
                                    }}
                                >
                                    REFERRAL CODE
                                </Typography>
                                <Box display="flex">
                                    <Input
                                        id={OnboardingFormFields.REFERRAL_CODE}
                                        type="text"
                                        value={referralCode}
                                        disableUnderline
                                        onChange={handleChange}
                                        sx={{
                                            flex: 1,
                                            marginLeft: '14px',
                                            '.MuiInputBase-input': {
                                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                            },
                                        }}
                                    />
                                </Box>
                            </FormControl>
                            {
                                errors.has(OnboardingFormFields.REFERRAL_CODE) ? (
                                    <Typography
                                        justifyContent="left"
                                        color="red"
                                        fontSize="x-small"
                                        sx={{
                                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                            paddingLeft: '16px',
                                        }}
                                    >
                                        {errors.get(OnboardingFormFields.REFERRAL_CODE)}
                                    </Typography>
                                ) : null
                            }
                        </Box>
                    </Box>

                    <Box display="flex" flexDirection="row" minWidth="fit-content">
                        <Button
                            size="large"
                            onClick={handleClickBack}
                            variant="contained"
                            sx={{
                                height: '45px',
                                borderRadius: '50px',
                                backgroundColor: '#1a202c',
                                whiteSpace: 'nowrap',
                                textTransform: 'none',
                                marginLeft: 'auto',
                                marginRight: '10px',
                                marginTop: '20px',
                                marginBottom: '-40px',
                            }}>
                            {`← Back`}
                        </Button>
                        <Button
                            size="large"
                            onClick={handleClickReview}
                            variant="contained"
                            sx={{
                                height: '45px',
                                borderRadius: '50px',
                                backgroundColor: '#0752FF',
                                whiteSpace: 'nowrap',
                                textTransform: 'none',
                                marginLeft: '10px',
                                marginRight: 'auto',
                                marginTop: '20px',
                                marginBottom: '-40px',
                            }}>
                            {`Next  →`}
                        </Button>
                    </Box>
                </Box>
            </Grid>
            {
                isMobile ? <MobileMenuBar/> : null
            }
        </Grid>
    );
};

export interface PriceQuotes {
    [coinName: string]: number;
}
export interface ExchangeRates {
    [countryCode: string]: number;
}
export interface LTV {
    [coinName: string]: number[];
}
export interface LoanMetrics {
    LTV: LTV;
    exchangeRates: ExchangeRates;
    priceQuotes: PriceQuotes;
}

export interface CryptoPriceQuoteData {
    id: string;
    cryptoCurrency: string;
    usdPriceQuote: string;
}
export interface ListCryptoPriceQuotesResponse {
    data: {
        listCryptoPriceQuotes: {
            items: CryptoPriceQuoteData[]
        }
    }
}

export interface CurrencyExchangeRateData {
    id: string;
    currency: string;
    usdRate: string;
}
export interface ListCurrencyExchangeRatesResponse {
    data: {
        listCurrencyExchangeRates: {
            items: CurrencyExchangeRateData[];
        }
    }
}


export const convertPriceQuotes = (givenData: CryptoPriceQuoteData[]): PriceQuotes => {
    const returnable = givenData.reduce((accumulator, currentValue) => {
        accumulator[currentValue.cryptoCurrency] = Number(currentValue.usdPriceQuote);
        return accumulator;
    }, {} as PriceQuotes);

    return returnable;
};

export const convertExchangeRates = (givenData: CurrencyExchangeRateData[]): ExchangeRates => {
    const returnable = givenData.reduce((accumulator, currentValue) => {
        accumulator[currentValue.currency] = Number(currentValue.usdRate);
        return accumulator;
    }, {"USD": 1} as PriceQuotes);

    return returnable;
};

export const LOAN_METRICS: LoanMetrics = {
    "LTV": {
        "BTC": [
            0.6,
            0.8,
            0.7,
            0.5
        ],
        "ETH": [
            0.7,
            0.8,
            0.6,
            0.5
        ],
        "ARB": [
            0.7,
            0.5,
            0.8,
            0.6
        ],
        "MATIC": [
            0.7,
            0.5,
            0.8,
            0.6
        ],
        "XRP": [
            0.5,
            0.8,
            0.7,
            0.6
        ],
        "ADA": [
            0.8,
            0.7,
            0.5,
            0.6
        ],
        "SOL": [
            0.5,
            0.8,
            0.6,
            0.7
        ],
        "DOT": [
            0.5,
            0.8,
            0.6,
            0.7
        ],
    },
    "exchangeRates": {
        "USD": 1,
        "ZAR": 18.67,
        "KRW": 1351.3,
        "PLN": 3.95,
        "INR": 83.34,
        "SGD": 1.35,
        "COP": 3810.6,
        "CZK": 23.35,
        "QAR": 3.64,
        "HKD": 7.83,
        "ILS": 3.76,
        "DKK": 6.89,
        "MXN": 16.48,
        "NZD": 1.66,
        "HUF": 359.8,
        "KZT": 446.48,
        "NOK": 10.73,
        "ISK": 138.81,
        "CAD": 1.36,
        "THB": 36.67,
        "AED": 3.67,
        "JPY": 151.58,
        "RSD": 108.1,
        "TRY": 32.01,
        "MAD": 10.06,
        "CHF": 0.902,
        "BGN": 1.81,
        "SAR": 3.75,
        "GBP": 0.792,
        "CLP": 945.65,
        "RON": 4.59,
        "EUR": 0.923,
        "SEK": 10.65,
        "AUD": 1.52,
        "PHP": 56.59,
        "EGP": 47.35
    },
    "priceQuotes": {
        "BTC": 68627.87958100362,
        "ETH": 3360.392291793909,
        "ARB": 0.921400000000000,
        "MATIC": 0.8975408229374418,
        "XRP": 0.5913981819516487,
        "ADA": 0.5828305773700927,
        "SOL": 178.14981229573775,
        "DOT": 6.2100000000000000
    }
};
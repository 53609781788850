/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateLoan = /* GraphQL */ `
  subscription OnCreateLoan(
    $filter: ModelSubscriptionLoanFilterInput
    $owner: String
  ) {
    onCreateLoan(filter: $filter, owner: $owner) {
      id
      loanAmount
      loanCurrency
      collateralAmount
      collateralCryptoCurrency
      interestAmount
      interestCurrency
      healthFactor
      status
      beneficiaryIban
      beneficiarySwiftCode
      beneficiaryName
      beneficiaryAddress
      loanWalletAddress
      loanWalletPublicKey
      referralCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateLoan = /* GraphQL */ `
  subscription OnUpdateLoan(
    $filter: ModelSubscriptionLoanFilterInput
    $owner: String
  ) {
    onUpdateLoan(filter: $filter, owner: $owner) {
      id
      loanAmount
      loanCurrency
      collateralAmount
      collateralCryptoCurrency
      interestAmount
      interestCurrency
      healthFactor
      status
      beneficiaryIban
      beneficiarySwiftCode
      beneficiaryName
      beneficiaryAddress
      loanWalletAddress
      loanWalletPublicKey
      referralCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteLoan = /* GraphQL */ `
  subscription OnDeleteLoan(
    $filter: ModelSubscriptionLoanFilterInput
    $owner: String
  ) {
    onDeleteLoan(filter: $filter, owner: $owner) {
      id
      loanAmount
      loanCurrency
      collateralAmount
      collateralCryptoCurrency
      interestAmount
      interestCurrency
      healthFactor
      status
      beneficiaryIban
      beneficiarySwiftCode
      beneficiaryName
      beneficiaryAddress
      loanWalletAddress
      loanWalletPublicKey
      referralCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateWallet = /* GraphQL */ `
  subscription OnCreateWallet($filter: ModelSubscriptionWalletFilterInput) {
    onCreateWallet(filter: $filter) {
      id
      address
      privateKey
      publicKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateWallet = /* GraphQL */ `
  subscription OnUpdateWallet($filter: ModelSubscriptionWalletFilterInput) {
    onUpdateWallet(filter: $filter) {
      id
      address
      privateKey
      publicKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteWallet = /* GraphQL */ `
  subscription OnDeleteWallet($filter: ModelSubscriptionWalletFilterInput) {
    onDeleteWallet(filter: $filter) {
      id
      address
      privateKey
      publicKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCryptoPriceQuote = /* GraphQL */ `
  subscription OnCreateCryptoPriceQuote(
    $filter: ModelSubscriptionCryptoPriceQuoteFilterInput
  ) {
    onCreateCryptoPriceQuote(filter: $filter) {
      id
      cryptoCurrency
      usdPriceQuote
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCryptoPriceQuote = /* GraphQL */ `
  subscription OnUpdateCryptoPriceQuote(
    $filter: ModelSubscriptionCryptoPriceQuoteFilterInput
  ) {
    onUpdateCryptoPriceQuote(filter: $filter) {
      id
      cryptoCurrency
      usdPriceQuote
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCryptoPriceQuote = /* GraphQL */ `
  subscription OnDeleteCryptoPriceQuote(
    $filter: ModelSubscriptionCryptoPriceQuoteFilterInput
  ) {
    onDeleteCryptoPriceQuote(filter: $filter) {
      id
      cryptoCurrency
      usdPriceQuote
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCurrencyExchangeRate = /* GraphQL */ `
  subscription OnCreateCurrencyExchangeRate(
    $filter: ModelSubscriptionCurrencyExchangeRateFilterInput
  ) {
    onCreateCurrencyExchangeRate(filter: $filter) {
      id
      currency
      usdRate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCurrencyExchangeRate = /* GraphQL */ `
  subscription OnUpdateCurrencyExchangeRate(
    $filter: ModelSubscriptionCurrencyExchangeRateFilterInput
  ) {
    onUpdateCurrencyExchangeRate(filter: $filter) {
      id
      currency
      usdRate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCurrencyExchangeRate = /* GraphQL */ `
  subscription OnDeleteCurrencyExchangeRate(
    $filter: ModelSubscriptionCurrencyExchangeRateFilterInput
  ) {
    onDeleteCurrencyExchangeRate(filter: $filter) {
      id
      currency
      usdRate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateShareableCode = /* GraphQL */ `
  subscription OnCreateShareableCode(
    $filter: ModelSubscriptionShareableCodeFilterInput
    $owner: String
  ) {
    onCreateShareableCode(filter: $filter, owner: $owner) {
      id
      type
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateShareableCode = /* GraphQL */ `
  subscription OnUpdateShareableCode(
    $filter: ModelSubscriptionShareableCodeFilterInput
    $owner: String
  ) {
    onUpdateShareableCode(filter: $filter, owner: $owner) {
      id
      type
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteShareableCode = /* GraphQL */ `
  subscription OnDeleteShareableCode(
    $filter: ModelSubscriptionShareableCodeFilterInput
    $owner: String
  ) {
    onDeleteShareableCode(filter: $filter, owner: $owner) {
      id
      type
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;

import React from 'react';
import Background from '../images/Bitlendr_transparent.svg'

const styles = {
    footer: {
        padding: '20px',
        backgroundColor: '#E6ECFB',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    columnsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginLeft: "10%"
    },
    column: {
        flex: 1,
        margin: '0 10px',
    },
    title: {
        color: 'blue',
        marginBottom: '10px',
    },
    link: {
        display: 'block',
        color: 'black',
        textDecoration: 'none',
        marginBottom: '5px',
        cursor: 'pointer',
    },
    copyright: {
        paddingTop: '10px',
        marginLeft: '10%'
    },
};

export const Footer = () => {
    const columns = [
        {
            title: "App",
            links: ["Login", "Feature request", "Status: Online"]
        },
        {
            title: "Help",
            links: ["FAQ", "Support"]
        },
        {
            title: "Legal",
            links: ["About", "Terms & Conditions", "Privacy Policy"]
        },
        {
            title: "Social",
            links: ["Instagram", "Telegram", "Discord", "Linkedin", "Tik-Tok"]
        },
    ];

    return (
        <div style={styles.footer}>
            <div
                // @ts-ignore
                style={styles.columnsContainer}
            >
                {columns.map((column, idx) => (
                    <div key={idx} style={styles.column}>
                        <h6 style={styles.title}>{column.title}</h6>
                        {column.links.map((link, linkIdx) => (
                            <a key={linkIdx} href="#" style={styles.link}>{link}</a>
                        ))}
                    </div>
                ))}
            </div>
            <div style={styles.copyright}>
                Copyright © 2023 Bitlendr.io. All rights reserved.
            </div>
        </div>
    );
};

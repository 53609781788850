import React from 'react';
import Lottie from 'react-lottie';
import lottie1 from '../../animations/lottie1.json';
import lottie2 from '../../animations/lottie2.json';
import lottie3 from '../../animations/lottie3.json';
import './CardGallery.css';
import {CardGalleryItem} from './types';

export interface CardGalleryMobileProps {
    title: string,
    label: string,
    items: CardGalleryItem[];
}

const animations = [lottie1, lottie2, lottie3];

export const CardGalleryMobile = ({ title, label, items }: CardGalleryMobileProps) => {
    return (
        <div
            // @ts-ignore
            style={{ ...scrollContainerStyles }}
        >
            <div style={{marginBottom: '2vh', marginTop: '2vh', alignItems: 'center', textAlign: 'center'}}>
                <h1 style={{fontSize: '2em'}}>
                    {title}
                </h1>
            </div>
            <div style={{ ...spanStyles, textAlign: 'center', marginBottom: '5vh', maxWidth: '30vw' }}>
                {label}
            </div>

            {items.map((item, idx) => (
                <div
                    key={idx}
                    // @ts-ignore
                    style={mobileCardStyles}
                >
                    <div
                        // @ts-ignore
                        style={textContainerStyles}
                    >
                        <div
                            // @ts-ignore
                            style={textStyles}
                        >
                            {item.title}
                        </div>
                    </div>
                    <Lottie
                        // @ts-ignore
                        style={lottieStyles}
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: animations[idx] ?? lottie1,
                        }}
                        isPaused={false}
                        isClickToPauseDisabled={true}
                    />
                </div>
            ))}
        </div>
    );
};

const scrollContainerStyles = {
    // overflowY: 'scroll',
    width: '100%',
    maxHeight: '100vh',
    padding: '10px 0',
    // Styles to hide the scrollbar
    scrollbarWidth: 'none', // For Firefox
    msOverflowStyle: 'none', // For Internet Explorer and Edge
    overflow: '-moz-scrollbars-none', // Older versions of Firefox
    '-webkit-scrollbar': {
        width: '0 !important' // For Chrome, Safari, and newer versions of Opera
    }
};

const lottieStyles = {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '-4vh'
};

const textStyles = {
    color: 'white',
    textAlign: 'left',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    fontSize: '1em',
};

const mobileCardStyles = {
    height: '400px',
    background: '#7CA5FF',
    borderRadius: '20px',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '15px',
    position: 'relative', // Add this line
    width: '90%',
    margin: '0 auto',
    marginBottom: '20px'
};

const spanStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    color: '#007BFF', // You can adjust the blue to your preference
    borderRadius: '20px',  // Adjust this value for more or less rounding
    padding: '5px 10px', // Adjust padding to your liking    width: 'fit-content',
    margin: '0 auto',
};

const textContainerStyles = {
    position: 'absolute',
    top: '20px',  // Positioning it just above the animation
    left: '40px',     // Adjusting based on card padding

};

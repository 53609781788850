/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLoan = /* GraphQL */ `
  query GetLoan($id: ID!) {
    getLoan(id: $id) {
      id
      loanAmount
      loanCurrency
      collateralAmount
      collateralCryptoCurrency
      interestAmount
      interestCurrency
      healthFactor
      status
      beneficiaryIban
      beneficiarySwiftCode
      beneficiaryName
      beneficiaryAddress
      loanWalletAddress
      loanWalletPublicKey
      referralCode
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listLoans = /* GraphQL */ `
  query ListLoans(
    $filter: ModelLoanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLoans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        loanAmount
        loanCurrency
        collateralAmount
        collateralCryptoCurrency
        interestAmount
        interestCurrency
        healthFactor
        status
        beneficiaryIban
        beneficiarySwiftCode
        beneficiaryName
        beneficiaryAddress
        loanWalletAddress
        loanWalletPublicKey
        referralCode
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWallet = /* GraphQL */ `
  query GetWallet($id: ID!) {
    getWallet(id: $id) {
      id
      address
      privateKey
      publicKey
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWallets = /* GraphQL */ `
  query ListWallets(
    $filter: ModelWalletFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWallets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address
        privateKey
        publicKey
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCryptoPriceQuote = /* GraphQL */ `
  query GetCryptoPriceQuote($id: ID!) {
    getCryptoPriceQuote(id: $id) {
      id
      cryptoCurrency
      usdPriceQuote
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCryptoPriceQuotes = /* GraphQL */ `
  query ListCryptoPriceQuotes(
    $filter: ModelCryptoPriceQuoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCryptoPriceQuotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cryptoCurrency
        usdPriceQuote
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCurrencyExchangeRate = /* GraphQL */ `
  query GetCurrencyExchangeRate($id: ID!) {
    getCurrencyExchangeRate(id: $id) {
      id
      currency
      usdRate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCurrencyExchangeRates = /* GraphQL */ `
  query ListCurrencyExchangeRates(
    $filter: ModelCurrencyExchangeRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCurrencyExchangeRates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        currency
        usdRate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShareableCode = /* GraphQL */ `
  query GetShareableCode($id: ID!) {
    getShareableCode(id: $id) {
      id
      type
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listShareableCodes = /* GraphQL */ `
  query ListShareableCodes(
    $filter: ModelShareableCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShareableCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

import React from 'react';
import {Status} from '../types';
import {Box, Grid, Typography} from "@mui/material";
import {LoanStatus} from './LoanStatus';

export interface LoanListItemProps {
    id: string;
    date: string;
    loanAmount: number;
    loanCurrency: string;
    collateralAmount: number;
    collateralCryptoCurrency: string;
    interestAmount: number;
    interestCurrency: string;
    healthFactor: number;
    status: Status;
    loanWalletAddress?: string;
    loanWalletPublicKey?: string;
    isMobile: boolean;
}

const statusColors: {[key: string]: string} = {
    [Status.PENDING]: '#7C7C7C',
    [Status.APPROVED]: '#0552FF',
    [Status.REJECTED]: '#E27171',
};
export const LoanListItem = ({
    id,
    date,
    loanAmount,
    loanCurrency,
    collateralAmount,
    collateralCryptoCurrency,
    interestAmount,
    interestCurrency,
    healthFactor,
    status,
    loanWalletAddress,
    loanWalletPublicKey,
    isMobile,
}: LoanListItemProps) => {

    return (
        <Grid item xs={isMobile ? 11 : 6}>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                marginTop="20px"
                sx={{
                    background: '#FAFAFA',
                    padding: '20px',
                    borderRadius: '20px',
                }}
            >
                <Grid container>
                    <Grid item xs={5}>
                        <Box
                            display="flex"
                            justifyContent="center"
                        >
                            <Box display="flex" flexDirection="column">
                                <Typography
                                    color="#1B2030"
                                    fontSize={isMobile ? 'xx-small' : 'x-small'}
                                    sx={{
                                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                    }}
                                >
                                    Number
                                </Typography>
                                <Typography
                                    color="#1B2030"
                                    fontSize={isMobile ? 'xx-small' : 'x-small'}
                                    width="100%"
                                    overflow="hidden"
                                    sx={{
                                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                    }}
                                >
                                    {id}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box
                            display="flex"
                            justifyContent="center"
                        >
                            <LoanStatus status={status} isMobile={isMobile}/>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box
                            display="flex"
                            justifyContent="right"
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                            >
                                <Typography
                                    color="#1B2030"
                                    fontSize={isMobile ? 'xx-small' : 'x-small'}
                                    sx={{
                                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                    }}
                                >
                                    {new Date(date).toLocaleDateString()}
                                </Typography>
                                <Typography
                                    color="#1B2030"
                                    fontSize={isMobile ? 'xx-small' : 'x-small'}
                                    alignSelf="flex-end"
                                    sx={{
                                        fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                                    }}
                                >
                                    {new Date(date).toLocaleTimeString()}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                <Box mt={isMobile ? 2 : 4} mb={isMobile ? 0 : 1} >
                    <Typography
                        alignContent="center"
                        justifyContent="center"
                        color="#1B2030"
                        fontSize={isMobile ? 'x-large' : 'xx-large'}
                        sx={{
                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                        }}
                    >
                        {collateralAmount} {collateralCryptoCurrency}
                    </Typography>
                </Box>

                <Box mb={isMobile ? 1 : 2} >
                    <Typography
                        alignContent="center"
                        justifyContent="center"
                        color={statusColors[status] ?? '#FAFAFA'}
                        fontSize={isMobile ? 'x-large' : 'xx-large'}
                        sx={{
                            fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                        }}
                    >
                        {loanAmount} {loanCurrency}
                    </Typography>
                </Box>

                <Box
                    mt={isMobile ? 2 : 4}
                    display="flex"
                    flexDirection="row"
                    width="100%"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="left"
                    >
                        <Typography
                            alignContent="left"
                            justifyContent="left"
                            color="#9D9D9D"
                            fontSize={isMobile ? 'xx-small' : 'x-small'}
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            DEPOSIT ADDRESS
                        </Typography>
                        <Typography
                            alignContent="left"
                            justifyContent="left"
                            color="#1B2030"
                            fontSize={isMobile ? 'xx-small' : 'x-small'}
                            sx={{
                                fontFamily: ['MonumentGrotesk-Medium', 'serif'].join(','),
                            }}
                        >
                            {loanWalletAddress}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
};